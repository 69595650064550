@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');


@import "bootstrap/bootstrap.less";

@z-index-1:				100;
@z-index-2:				200;
@z-index-3:				300;
@z-index-4:				400;
@z-index-10:			1000;

/////////////////////////////////////////////////////////
//
// General Scaffolding
//
/////////////////////////////////////////////////////////

html,
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {

}

.page-block {
	.make-sm-column(4);
	margin-bottom: 30px;
}

	.page-block--wide {
		.make-sm-column(8);
	}
	
	.page-block--half {
		.make-sm-column(6);
	}

.embed-responsive {
	margin-top: 30px;
	box-shadow: 0 5px 10px -5px rgba(0,0,0,0.15);
	border-radius: 2px;
	overflow: hidden;
}

/////////////////////////////////////////////////////////
//
// Typography
//
/////////////////////////////////////////////////////////

h1 {
	margin-top: 0;
	font-size: 32px;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 21px;
}



/////////////////////////////////////////////////////////
//
// Buttons
//
/////////////////////////////////////////////////////////


.btn {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
}

.btn-primary,
.btn-success,
.btn-danger {
	border-color: transparent !important;
}



/////////////////////////////////////////////////////////
//
// Forms
//
/////////////////////////////////////////////////////////

.form-control {
	box-shadow: none !important;
}

#veterinarian_form_DateOfBirth_month {
	width: 100px;
	margin-right: 10px;
}

#veterinarian_form_DateOfBirth_year {
	width: 100px;
	margin-left: 10px;
}

.form-list {
	.make-row();
	margin-bottom: 0;
	
	dt {
		.make-sm-column(6);
		clear: both;
	}
	
	dd {
		.make-sm-column(6);
	}
}

/////////////////////////////////////////////////////////
//
// Boxes
//
/////////////////////////////////////////////////////////

.box {
	background: white;
	border-radius: 2px;
	box-shadow: 0 5px 10px -5px rgba(0,0,0,0.15);
}

		.box--jumbotron {
			margin-top: -160px;
		}
		
		.box--form {
			margin-top: -160px;
			margin-bottom: 30px;
		}

	.box-title {
		font-weight: bold;
		position: relative;
		margin-top: 0;
		font-size: 28px;
	}
	
	.box-content {
		padding: 30px 30px 20px;
	}
	
	.box-actions {
		border-top: 1px solid #f5f5f5;
		text-align: right;
		padding: 15px 30px;
		border-radius: 0 0 2px 2px;
	}

/////////////////////////////////////////////////////////
//
// Page Header + Navigation
//
/////////////////////////////////////////////////////////

.page-header {
	background: none;
	border: 0; padding: 0; margin: 0;
	background: white;
	z-index: @z-index-3;
	box-shadow: 0 0 2px rgba(0,0,0,0.05);
}

	.brand {
		.make-sm-column(5);
	}
	
		.brand-link {
			display: block;
			padding: 20px 0;
		}
	
		.brand-logo {
			width: 180px;
		}
	
	.primaryNavigation {
		.make-sm-column(7);
		
		.nav {
			float: right;
			
			> li {
				float: left;
				margin-left: 10px;
				
				a {
					text-transform: uppercase;
					color: @text-color;
					font-weight: bold;
					padding: 22px 12px 17px;
					border-bottom: 4px solid transparent;
					letter-spacing: 1px;
					
					&:hover,
					&:focus {
						border-bottom-color: @brand-primary;
						background: none;
						color: @text-color;
					}
				}
			}
		}
	}


/////////////////////////////////////////////////////////
//
// Jumbotron
//
/////////////////////////////////////////////////////////

.jumbotron {
	min-height: 300px;
	background: url("/images/bg-jumbotron.jpg") center center no-repeat;
	background-size: cover;
	padding-top: 50px;
	padding-bottom: 50px;
	
}

	.jumbotron-box {
		.make-sm-column(6);
		margin-top: 100px;
		margin-bottom: 50px;
	}



/////////////////////////////////////////////////////////
//
// Main Content
//
/////////////////////////////////////////////////////////

.section--main {
	background: #f5f5f5;
	padding: 40px 0 10px;
	
	h1 {
		font-weight: bold;
		font-size: 36px;
		position: relative;
		margin-bottom: 15px;
	}
}

.page-intro {
	
	h1 {
		margin-bottom: 0;
	}
	
	h2 {
		margin-top: 5px;
		color: rgba(0,0,0,0.6);
		font-weight: 300;
		font-size: 28px;
	}
	
	p {
		font-weight: 300;
		font-size: 18px;
	}
}


/////////////////////////////////////////////////////////
//
// Page Footer
//
/////////////////////////////////////////////////////////

.page-footer {
	color: rgba(255,255,255,0.6);
	padding: 50px 0 20px;
	
	a {
		color: rgba(255,255,255,0.6);
	}
}

	.page-footer-brand {
		width: 160px;
		margin-bottom: 10px;
	}